<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
       <b-link class="brand-logo" >
          <h2 class="brand-text text-success ml-0">
            N9VA
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Trocar senha
        </b-card-title>
        <b-card-text class="mb-2">
          Sua nova senha deve ser diferente da senha anterior
        </b-card-text>

        <!-- form -->
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
          >

            <!-- password -->
            <b-form-group
              
              label="Nova senha"
              label-for="reset-password-new"
            >
             
                <b-input-group
                  v-if="cmd_enable"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <b-input-group
                  v-else
                  class="input-group-merge"
                >
                  <b-form-input
                    disabled
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>



            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirmar senha"
            >
         
                <b-input-group
                  v-if="cmd_enable"
                  class="input-group-merge"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="confirm_password"
                    :type="password2FieldType"
                    class="form-control-merge"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <b-input-group
                  v-else
                  
                  class="input-group-merge"
                >
                  <b-form-input
                    disabled
                    id="reset-password-confirm"
                    v-model="confirm_password"
                    :type="password2FieldType"
                    class="form-control-merge"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>

            </b-form-group>

            <!-- submit button -->
            <b-button
            v-if="cmd_enable"
              block
              variant="success"
              @click="changePassword"
            >
              Definir nova senha
            </b-button>

            <b-button
              v-else
              disabled
              block
              variant="success"
            >
              Definir nova senha
            </b-button>
          </b-form>

        <p class="text-center mt-2">
          <b-link :to="{path:'/'}">
            <feather-icon icon="ChevronLeftIcon" /> Voltar para login
          </b-link>
        </p>

      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import store from '@/store/index'
import axios from "axios";

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      cmd_enable: true,
      Swal: require('sweetalert2'),
      userEmail: '',
      confirm_password: '',
      password: '',
      token: '',

      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  created(){
    this.token = new URL(window.location.href).searchParams.get('token');
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async changePassword() {
      var self = this;
      if(this.password == this.confirm_password && this.password.length >= 1){
        let data = {
          action: "resetPwdLong",
          value: { token: this.token, password: this.password }
        }
        const headers = { 
          "Authorization": store.state.token_api,
        };
        await axios.post('https://tekie.cloud:3031/authmanagement', data, { headers })

        .then(function (response) {
          Swal.fire(
          'Alterado!',
          'Senha alterada com sucesso!',
          'success'
          )
          self.cmd_enable = false;
        })
        .catch(function (e) {
          //"Erro ao tentar trocar senha: ", e)
          self.$toast({
          component: ToastificationContent,
          props: {
            title: 'Algo deu errado',
            icon: 'XIcon',
            variant: 'danger',
            },
          })
        });
        
      } else{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Revise sua nova senha',
            icon: 'XIcon',
            variant: 'danger',
            },
          })
      }
      
      
      
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
